<template>
  <v-card flat>
    <v-card-text>
      <div id="stackedTimeSeriesMonthly">
        <apex-chart
          :key="chartId"
          height="400"
          :options="stackedColumnChartOptions100"
          :series="localSeriesData"
        ></apex-chart>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, watchEffect, reactive } from 'vue'
import { stackedColumnChartOptions100 as initialOptions } from '@/assets/chartOptions/market'
import { useDataStore } from '@/stores/Data'

export default {
    name: 'StackedTimeSeriesMonthly',
    props: {
        series: {
            type: String,
            default: 'monthlyStackedSalesByMfr',
        }
    },
    setup(props) {
        const dataStore = useDataStore()
        const localSeriesData = ref([])
        const chartId = ref(0)
        const stackedColumnChartOptions100 = reactive({ ...initialOptions })

        watchEffect(() => {
            if (dataStore) {
                dataStore.fetchSeriesData(props.series).then(({ seriesData, xaxisCategories }) => {
                    localSeriesData.value = seriesData
                    if (xaxisCategories) {
                        stackedColumnChartOptions100.xaxis.categories = xaxisCategories
                    }
                    chartId.value += 1
                })
            }
        })

        return {
            localSeriesData,
            chartId,
            stackedColumnChartOptions100
        }
    },
}
</script>
